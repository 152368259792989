import * as React from "react";

import { Link, navigate } from "gatsby";

import { Button } from "@global";

// markup
const NotFoundPage = () => {
  return (
    <>
      <h2>Error/404</h2>
      <p>Whoops! It looks like the page you’re looking for can’t be found, or doesn’t exist.</p>
      <Button
        linkText="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
    </>
  );
};

export default NotFoundPage;
